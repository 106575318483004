<template>
  <b-card
    class="x-analysis-card"
  >
    <div>
      <b-img
        :src="require('@/assets/images/cx/product.svg')"
        fluid class="x-section-icon"
      />
      <span class="font-weight-bolder text-secondary ml-50">产品分析</span>
    </div>
    <b-card-header>
      <span class="text-secondary">产品数量</span>
      <span class="font-weight-bolder font-large-2">
        {{ allCount }}
      </span>
    </b-card-header>
    <b-card-body>
      <b-row cols="12">
        <b-col cols="4" class="p-0">
          <div class="d-flex x-w-100 align-items-center x-text-cut">
            <span class="text-secondary mr-50">日同比</span>
            <span class="x-text-bold x-text-cut">{{dayPercent}}%<feather-icon :icon="dayPercent < 0? 'ArrowDownIcon' : 'ArrowUpIcon'" v-if="dayPercent !== 0" :color="dayPercent < 0? 'red' : 'green'"/></span>
          </div>
        </b-col>
        <b-col cols="4"  class="p-0">
          <div class="d-flex align-items-center justify-content-center x-text-cut">
            <span class="text-secondary mr-50">周同比</span>
            <span class="x-text-bold x-text-cut">{{weekPercent}}%<feather-icon :icon="weekPercent < 0? 'ArrowDownIcon' : 'ArrowUpIcon'" v-if="weekPercent !== 0" :color="weekPercent < 0? 'red' : 'green'"/></span>
          </div>
        </b-col>
        <b-col cols="4"  class="p-0">
          <div class="d-flex align-items-center justify-content-end x-text-cut">
            <span class="text-secondary mr-50">月同比</span>
            <span class="x-text-bold x-text-cut">{{monthPercent}}%<feather-icon :icon="monthPercent < 0? 'ArrowDownIcon' : 'ArrowUpIcon'" v-if="monthPercent !== 0" :color="monthPercent < 0? 'red' : 'green'"/></span>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-footer>
      <b-row cols="12">
        <b-col cols="6" class="d-flex align-items-center p-0 x-state-dot x-text-cut ">
          <span class="font-medium-3 mr-50 text-success">●</span>
          <span class="text-secondary">启用数量：</span>
          <span class="x-text-bold x-text-cut">{{allCount - disableCout}}</span>
        </b-col>
        <b-col cols="6" class="d-flex align-items-center p-0 x-state-dot justify-content-end x-text-cut">
          <span class="font-medium-3 mr-50 text-danger">●</span>
          <span class="text-secondary">禁用数量：</span>
          <span class="x-text-bold x-text-cut">{{disableCout}}</span>
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardHeader, BCardBody, BCardFooter, BImg } from 'bootstrap-vue'
import {
  queryProductCount,
} from '@/api/home/product-analysis'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BImg,
  },
  data() {
    return {
      allCount: 0,
      enableCount: 0,
      disableCout: 0,
      dayPercent: 0,
      weekPercent: 0,
      monthPercent: 0,
    }
  },
  created() {
    // 查询差评熟练param1：状态 param2: 时间戳（大于100）
    queryProductCount(undefined, 0).then(resp => {
      this.allCount = resp.data.result
      queryProductCount(0, 0).then(resp1 => {
        this.disableCout = resp1.data.result
      })
      // 日同比
      queryProductCount(undefined, new Date().getTime() - 3600 * 1000 * 24).then(resp2 => {
        let count = this.allCount - resp2.data.result
        this.dayPercent = Number((count / resp2.data.result * 100).toFixed(0))
      })
      // 周同比
      queryProductCount(undefined, new Date().getTime() - 3600 * 1000 * 24 * 7).then(resp3 => {
        let count = this.allCount - resp3.data.result
        this.weekPercent = Number((count / resp3.data.result * 100).toFixed(0))
      })
      // 月同比
      queryProductCount(undefined, new Date().getTime() - 3600 * 1000 * 24 * 30).then(resp4 => {
        let count = this.allCount - resp4.data.result
        this.monthPercent = Number((count / resp4.data.result * 100).toFixed(0))
      })
    })
  },
  methods: {
  },
}
</script>
