<template>
  <b-card
    class="card-tiny-line-stats"
    style="height: 30rem"
  >
    <span class="font-weight-bolder">产品占比分析</span>
    <div ref="chart" style="width: 40rem; height: 30rem;"></div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import Vue from 'vue'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/grid'
import 'echarts/lib/component/title'
import {
  queryAllProducts,
} from '@/api/home/proportion-analysis'
import { count as deviceCount } from '@/api/device-manage/product-list'
// import { $themeColors } from '@themeConfig'
Vue.prototype.$echarts = echarts

export default {
  components: {
    BCard,
  },
  data() {
    return {
      productCount: 0,
      products: [],
      productsClassfy: [],
      // 排名值
      productSort: 6,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      queryAllProducts().then(resp => {
        let pds = resp.data.result
        this.productCount = pds.length
        if (pds.length > 6) {
          this.products = pds.slice(0, 6)
        } else {
          this.products = pds
        }
        let index = 0
        pds.forEach(item => {
          deviceCount(item.id).then(resp2 => {
            index++
            item.count = resp2.data.result
            if (index === pds.length) {
              pds = pds.sort((a, b) => b.count - a.count)
              if (pds.length > this.productSort) {
                let other = {
                  name: '其他',
                  photoUrl: '',
                  count: 0,
                }
                let i = 0
                pds.forEach(pd => {
                  i++
                  if (i <= this.productSort) {
                    this.productsClassfy.push(pd)
                  } else {
                    other.count += pd.count
                  }
                })
                this.productsClassfy.push(other)
              } else {
                this.productsClassfy = pds
              }
              // 并列出前五和其他
              let arr = []
              this.productsClassfy.forEach((itemf, indexf) => {
                let obj = {}
                obj.name = itemf.name
                obj.value = itemf.count
                arr.push(obj)
                if (indexf === this.productsClassfy.length - 1) {
                  const myChart = this.$echarts.init(this.$refs.chart)
                  const option = {
                    toolbox: {
                      show: true,
                      feature: {
                        mark: { show: true },
                        dataView: { show: true, readOnly: false },
                        restore: { show: true },
                        saveAsImage: { show: true },
                      },
                    },
                    tooltip: {
                      show: true, // 默认值true，可选为：true（显示） | false（隐藏）
                      zlevel: 1, // 默认值1，一级层叠控制。每一个不同的zlevel将产生一个独立的canvas，相同zlevel的组件或图标将在同一个canvas上渲染。zlevel越高越靠顶层，canvas对象增多会消耗更多的内存和性能，并不建议设置过多的zlevel，大部分情况可以通过二级层叠控制z实现层叠控制。
                      z: 8, // 默认值8，二级层叠控制，同一个canvas（相同zlevel）上z越高约靠顶层。
                      showContent: true, // 默认值true，tooltip主体内容显示策略，只需tooltip触发事件或显示axisPointer而不需要显示内容时可配置该项为false，
                    },
                    series: [
                      {
                        name: '设备数量占比',
                        type: 'pie',
                        radius: '70%',
                        center: ['60%', '45%'],
                        roseType: 'area',
                        itemStyle: {
                          borderRadius: 10,
                          normal: {
                            color: params => {
                              // 自定义颜色
                              let colorList = [
                                '#836AF9', '#FFE802', '#299AFF', '#06B7CF', '#28DAC6', '#FFA1A1', '#4F5D70',
                              ]
                              return colorList[params.dataIndex]
                            },
                          },
                        },
                        data: arr,
                      },
                    ],
                  }
                  myChart.setOption(option)
                }
              })
            }
          })
        })
      })
    },
  },
}
</script>
