<template>
  <e-charts
    ref="line"
    autoresize
    :options="line"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  watch: {
    optionData: {
      immediate: true,
      handler(option) {
        if (option) {
          this.line = {
            // Make gradient line here
            visualMap: [{
              show: true,
              type: 'continuous',
              min: 0,
              max: 400,
            }],
            grid: {
              width: '96%',
              left: '60px',
              top: '10px',
              show: false,
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'line',
                lineStyle: {
                  type: 'dotted',
                  // color: 'rgba(0, 0, 0, 0.3)',
                },
              },
            },
            xAxis: [{
              splitLine: {
                show: false,
              },
              boundaryGap: false,
              data: option.xAxisData,
              smooth: true,
            }],
            yAxis: {
              type: 'value',
              splitLine: { show: false },
            },
            series: {
              type: 'line',
              showSymbol: false,
              areaStyle: {
                normal: {
                  show: true,
                },
              },
              data: option.series,
              smooth: option.smooth ? option.smooth : false,
            },
          }
        }
      },
    },
  },
  data() {
    return {
      line: {
        // Make gradient line here
        visualMap: [{
          show: true,
          type: 'continuous',
          min: 0,
          max: 400,
        }],
        grid: {
          width: '96%',
          left: '60px',
          top: '10px',
          show: false,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              type: 'dashed',
              // color: 'rgba(0, 0, 0, 0.3)',
            },
          },
        },
        xAxis: [{
          splitLine: {
            show: false,
          },
          boundaryGap: false,
          data: this.optionData.xAxisData,
          smooth: true,
        }],
        yAxis: {
          type: 'value',
          splitLine: { show: false },
        },
        series: {
          type: 'line',
          showSymbol: false,
          areaStyle: {
            normal: {
              show: true,
            },
          },
          data: this.optionData.series,
          smooth: this.optionData.smooth ? this.optionData.smooth : false,
        },
      },
    }
  },
}
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
