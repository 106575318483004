<template>
  <b-card
    body-class="pb-50"
  >
    <!--<span class="font-weight-bolder mb-1">当前在线</span>-->
    <!--<h2 class="font-weight-bolder mb-1">-->
    <!--{{currOnlineDeviceCount}}-->
    <!--</h2>-->
    <div class="font-weight-bolder mb-1">最近30天设备在线趋势</div>
    <div class="mb-1 mb-sm-0">
      <span class="text-muted">设备在线趋势图</span>
    </div>
    <!-- chart -->
    <vue-apex-charts
      v-if="sericeData"
      height="255"
      :options="statisticsOrder.chartOptions"
      :series="sericeData"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { multi } from '@/api/dashboards'

const $secondColor = '#f3f3f3'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  data() {
    return {
      // 当前在线设备数量
      currOnlineDeviceCount: 0,
      // 设备总数
      allDeviceCount: 0,
      //  未激活设备数量
      notActiveCount: 0,
      sericeData: [],
      statisticsOrder: {
        chartOptions: {
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '30%',
              // startingShape: 'rounded',
              colors: {
                backgroundBarColors: [$secondColor, $secondColor, $secondColor, $secondColor, $secondColor],
                backgroundBarRadius: 5,
              },
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: true,
          },
          colors: [$themeColors.warning],

          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
  // created() {
  //   this.deviceInfos()
  // },
  mounted() {
    this.deviceInfos()
  },
  methods: {
    deviceInfos() {
      const params = [
        {
          dashboard: 'device',
          dimension: 'current',
          group: 'deviceOnline',
          measurement: 'record',
          object: 'status',
          params: {
            state: 'online',
          },
        },
        {
          dashboard: 'device',
          dimension: 'current',
          group: 'deviceCount',
          measurement: 'record',
          object: 'status',
        },
        {
          dashboard: 'device',
          dimension: 'current',
          group: 'deviceNotActive',
          measurement: 'record',
          object: 'status',
          params: {
            state: 'notActive',
          },
        },
        {
          dashboard: 'device',
          dimension: 'aggOnline',
          group: 'aggOnline',
          measurement: 'record',
          object: 'status',
          params: {
            format: 'yyyy-MM-dd',
            limit: 30,
            time: '1d',
          },
        },
      ]
      multi(params).then(resp => {
        const sericeDataArr = []
        resp.data.result.forEach(item => {
          if (item.group === 'deviceNotActive') {
            // 未激活设备数量
            this.notActiveCount = item.data.value
          }
          if (item.group === 'deviceOnline') {
            // 当前在线设备数量
            this.currOnlineDeviceCount = item.data.value
          }
          if (item.group === 'deviceCount') {
            // 设备总数
            this.allDeviceCount = item.data.value
          }
          if (item.group === 'aggOnline') {
            sericeDataArr.push(item.data.value)
          }
        })
        this.sericeData = [{
          name: '设备数量',
          data: sericeDataArr.reverse(),
        }]
      })
    },
  },
}
</script>
