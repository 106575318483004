<template>
  <b-card
    class="x-analysis-card"
    style="height: 30rem"
  >
    <div>
      <b-img
        :src="require('@/assets/images/cx/upgrade.svg')"
        fluid class="x-section-icon"
      />
      <span class="font-weight-bolder text-secondary ml-50">固件升级数量：{{this.warningCount + this.processingCount + this.successCount + this.failedCount}}</span>
    </div>
    <div ref="chart" style="width: 40rem; height: 30rem;"></div>
  </b-card>
</template>

<script>
import { BCard, BImg } from 'bootstrap-vue'
import Vue from 'vue'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/grid'
import 'echarts/lib/component/title'
import {
  queryFirmCount,
} from '@/api/home/firm-analysis'
// import { $themeColors } from '@themeConfig'
import theme from '@core/components/charts/echart/theme.json'

echarts.registerTheme('theme-color', theme)

Vue.prototype.$echarts = echarts

export default {
  components: {
    BCard,
    BImg,
  },
  data() {
    return {
      allCount: 0,
      warningCount: 0,
      processingCount: 0,
      successCount: 0,
      failedCount: 0,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      queryFirmCount('waiting').then(resp => {
        this.warningCount = resp.data.result
        queryFirmCount('processing').then(resp2 => {
          this.processingCount = resp2.data.result
          queryFirmCount('success').then(resp3 => {
            this.successCount = resp3.data.result
            queryFirmCount('failed').then(resp4 => {
              this.failedCount = resp4.data.result
              const myChart = this.$echarts.init(this.$refs.chart, theme)
              const option = {
                grid: {
                  left: '15%',
                  right: '3%',
                  top: '10%',
                  bottom: '50%',
                  containLabel: false,
                },
                tooltip: {
                  trigger: 'item',
                },
                legend: {
                  top: '5%',
                  left: 'center',
                },
                series: [
                  {
                    name: '升级状态数量',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                      show: false,
                      position: 'center',
                    },
                    emphasis: {
                      label: {
                        show: true,
                        fontSize: '40',
                        fontWeight: 'bold',
                      },
                    },
                    labelLine: {
                      show: false,
                    },
                    itemStyle: {
                      borderRadius: 10,
                      normal: {
                        color: params => {
                          // 自定义颜色
                          let colorList = [
                            '#f9aa0f', '#FFE802', '#299AFF', '#06B7CF', '#28DAC6',
                          ]
                          return colorList[params.dataIndex]
                        },
                      },
                    },
                    data: [
                      { value: this.warningCount, name: '等待升级' },
                      { value: this.processingCount, name: '升级中' },
                      { value: this.successCount, name: '升级完成' },
                      { value: this.failedCount, name: '升级失败' },
                    ],
                  },
                ],
              }
              option.series[0].data = [{ value: this.warningCount, name: '等待升级' }, { value: this.processingCount, name: '升级中' }, { value: this.successCount, name: '升级完成' }, { value: this.failedCount, name: '升级失败' }]
              myChart.clear()
              myChart.setOption(option)
            })
          })
        })
      })
    },
  },
}
</script>
