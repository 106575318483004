import request from '@/auth/jwt/useJwt'

/*  查询产品总数 */
export function queryFirmCount(state) {
  let requestUrl = '/firmware/upgrade/history/_count'
  if (state !== undefined) {
    requestUrl += `?terms[0].column=state&terms[0].value=${state}`
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
// 查询所有产品
export function queryAllProducts() {
  return request.axiosIns({
    url: '/device-product/_query/no-paging?sorts[0].name=createTime&sorts[0].order=desc',
    method: 'get',
  })
}
