<template>
  <div>
  <b-row>
    <b-col md="4">
      <product-analysis/>
    </b-col>
    <b-col md="4">
      <device-analysis/>
    </b-col>
    <b-col md="4">
      <alarm-view/>
    </b-col>
  </b-row>
    <b-row>
      <b-col md="6">
          <firm-analysis/>
      </b-col>
      <b-col md="6">
        <proportional-analysis/>
      </b-col>
    </b-row>
    <b-row md="12">
      <b-col md="12">
        <currently-online/>
      </b-col>
    </b-row>
    <b-row>
    <b-col md="12">
      <homechart-line/>
    </b-col>
  </b-row>
    <b-row>
    <b-col md="12">
      <alarm-analysis/>
    </b-col>
  </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import DeviceAnalysis from './DeviceAnalysis.vue'
import AlarmView from './AlarmView.vue'
import FirmAnalysis from './FirmAnalysis.vue'
import ProductAnalysis from './ProductAnalysis.vue'
import HomechartLine from './HomechartLine.vue'
import ProportionalAnalysis from './ProportionalAnalysis.vue'
import CurrentlyOnline from './CurrentlyOnline.vue'
import AlarmAnalysis from './AlarmAnalysis.vue'

export default {
  components: {
    AlarmAnalysis,
    CurrentlyOnline,
    HomechartLine,
    BRow,
    BCol,
    ProductAnalysis,
    DeviceAnalysis,
    AlarmView,
    FirmAnalysis,
    ProportionalAnalysis,
  },
  data() {
    return {
      data: {},
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
