import request from '@/auth/jwt/useJwt'

/*  设备告警 */
export function queryAlarmCount(state, startTime, endTime) {
  let requestUrl = `/device/alarm/history/_count?terms[0].column=alarmTime$btw&terms[0].value=${startTime},${endTime}&terms[1].column=state&terms[1].value=${state}`
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
// 查询所有产品
export function queryAllProducts() {
  return request.axiosIns({
    url: '/device-product/_query/no-paging?sorts[0].name=createTime&sorts[0].order=desc',
    method: 'get',
  })
}
