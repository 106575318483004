<template>
  <b-overlay
    variant="light"
    :show="showLoading"
    spinner-variant="primary"
    opacity=".75"
    rounded="sm"
  >
    <b-card style="min-height: 30rem">
    <div class="font-weight-bolder mb-2">设备消息</div>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="mb-1 mb-sm-0">
            <span class="text-muted">设备消息趋势图</span>
      </div>
      <div>
        <b-button-group
          size="50"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            active
            @click="timeFlag = '1h', search()"
          >
            1小时
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="timeFlag = '1d', search()"
          >
            1天
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="timeFlag = '7d', search()"
          >
            7天
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="timeFlag = '30d', search()"
          >
            30天
          </b-button>
        </b-button-group>
      </div>
      <div>
        <el-date-picker
          v-model="dateNtim"
          style="width: 14rem"
          type="datetime"
          placeholder="选择日期时间">
        </el-date-picker>
      </div>
    </div>
    <!-- echart -->
    <app-echart-line
      v-if="showFlag"
      :optionData="option"
    />
  </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BButton, BButtonGroup, BOverlay } from 'bootstrap-vue'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'
import { multi } from '@/api/dashboards'

export default {
  components: {
    BCard,
    AppEchartLine,
    BButton,
    BButtonGroup,
    BOverlay,
  },
  data() {
    return {
      showFlag: false,
      homeLineSerice: [],
      option: {
        xAxisData: [],
        series: [],
        smooth: true,
      },
      dateNtim: this.getCurrentTime(),
      timeFlag: '30d',
      showLoading: false,
    }
  },
  // created() {
  //   this.homeLineInfos()
  // },
  mounted() {
    this.homeLineInfos()
  },
  methods: {
    homeLineInfos() {
      this.showLoading = true
      let paramFlag = {}
      if (this.timeFlag === '30d') {
        paramFlag = {
          format: 'MM月dd日HH时',
          from: this.getTimeInterval(this.getCurrentTime(), 30),
          limit: 60,
          time: '12h',
          to: this.dateNtim,
        }
      } else if (this.timeFlag === '7d') {
        paramFlag = {
          format: 'MM月dd日HH时mm分',
          from: this.getTimeInterval(this.getCurrentTime(), 7),
          limit: 60,
          time: '168m',
          to: this.dateNtim,
        }
      } else if (this.timeFlag === '1d') {
        paramFlag = {
          format: 'MM月dd日HH时',
          from: this.getTimeInterval(this.getCurrentTime(), 1),
          limit: 60,
          time: '24m',
          to: this.dateNtim,
        }
      } else if (this.timeFlag === '1h') {
        paramFlag = {
          format: 'HH时mm分',
          from: this.tiemRedH(this.getCurrentTime(), 1),
          limit: 60,
          time: '1m',
          to: this.dateNtim,
        }
      }
      const params = [
        {
          dashboard: 'gatewayMonitor',
          dimension: 'agg',
          group: 'sameDay',
          measurement: 'received_message',
          object: 'deviceGateway',
          params: paramFlag,
        },
      ]
      multi(params).then(resp => {
        let xArr = []
        let seriesArr = []
        resp.data.result.forEach((item, index) => {
          xArr.push(item.data.timeString)
          seriesArr.push(item.data.value)
          if (index === resp.data.result.length - 1) {
            this.option = {
              xAxisData: xArr,
              series: seriesArr,
              smooth: true,
            }
            this.showFlag = true
          }
        })
        this.showLoading = false
      })
    },
    getCurrentTime() {
      // 获取当前时间
      const that = this
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const hh = new Date().getHours() < 10 ? `0${new Date().getHours()}` : new Date().getHours()
      const mf = new Date().getMinutes() < 10 ? `0${new Date().getMinutes()}` : new Date().getMinutes()
      const ss = new Date().getSeconds() < 10 ? `0${new Date().getSeconds()}` : new Date().getSeconds()
      that.gettime = `${yy}-${mm}-${dd} ${hh}:${mf}:${ss}`
      return that.gettime
    },
    // 时间减30天
    getTimeInterval(date, time) { // time为减去的指定天数
      const stime = date.substr(date.indexOf(' '))
      const date1 = new Date(date)
      const date2 = new Date(date1)
      // -30为30天前，+30可以获得30天后的日期
      date2.setDate(date1.getDate() - time)
      // 30天前（日，月份判断是否小于10，小于10的前面+0）
      const agoDay = `${date2.getFullYear()}-${date2.getMonth() + 1 < 10 ? `0${date2.getMonth() + 1}` : date2.getMonth() + 1}-${date2.getDate() < 10 ? `0${date2.getDate()}` : date2.getDate()}`
      return `${agoDay}${stime}`
    },
    // 时间减去一个小时
    tiemRedH(time, hount) {
      // '2021-6-19 14:00:00'
      const pre = time.substr(0, time.lastIndexOf('-') + 1)
      let day = time.substring(time.lastIndexOf('-') + 1, time.indexOf(' '))
      let hour = time.substring(time.indexOf(' '), time.indexOf(':'))
      const after = time.substring(time.indexOf(':'))
      hour -= hount
      if (hour < 0) {
        day -= hount
        hour = 23
      }
      if (hour < 10) {
        hour = `0${hour}`
      }
      return `${pre}${day} ${hour}${after}`
    },
    search() {
      this.homeLineSerice = []
      this.homeLineInfos()
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
