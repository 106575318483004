<template>
  <b-overlay
    variant="light"
    :show="showLoading"
    spinner-variant="primary"
    opacity=".75"
    rounded="sm"
  >
    <b-card
      class="card-tiny-line-stats"
      body-class="pb-50"
      style="height: 100%"
    >
      <div class="font-weight-bolder mb-1">设备告警</div>
      <div class="d-flex justify-content-md-between flex-centered">
        <div class="mb-1 mb-sm-0">
          <span class="text-muted">设备告警趋势图</span>
        </div>
        <div>
          <b-button-group
            size="50"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              active
              @click="timeFlag = '3d', search()"
            >
              3天
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="timeFlag = '7d', search()"
            >
              7天
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="timeFlag = '10d', search()"
            >
              10天
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="timeFlag = '30d', search()"
            >
              30天
            </b-button>
          </b-button-group>
        </div>
        <div>
          <!-- <flat-pickr
            v-model="dateNtim"
            class="form-control"
            :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
          /> -->
          <el-date-picker
            v-model="dateNtim"
            style="width: 14rem"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </div>
      </div>
      <b-card-body>
        <div ref="chart" style="width: 100%; height: 400px;"></div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BCardBody, BButtonGroup, BButton, BOverlay } from 'bootstrap-vue'
import Vue from 'vue'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/grid'
import 'echarts/lib/component/title'
// import flatPickr from 'vue-flatpickr-component'
// import { $themeColors } from '@themeConfig'
import { queryAlarmCount } from '@/api/home/alarm-analysis'
import theme from '@core/components/charts/echart/theme.json'

echarts.registerTheme('theme-color', theme)

Vue.prototype.$echarts = echarts

export default {
  components: {
    BCard,
    BCardBody,
    BButtonGroup,
    BButton,
    // flatPickr,
    BOverlay,
  },
  data() {
    return {
      dateNtim: new Date(),
      timeFlag: '7d',
      uid: undefined,
      showLoading: false,
    }
  },
  mounted() {
    this.homeLineInfos()
  },
  methods: {
    init(dateArr, solveArr, newArr) {
      const myChart = this.$echarts.init(this.$refs.chart, theme)
      const option = {
        grid: {
          width: '96%',
          left: '60px',
          top: '10px',
          show: false,
        },
        legend: {
          data: ['未处理', '已处理'],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              type: 'dashed',
              // color: 'rgba(0, 0, 0, 0.3)',
            },
          },
        },
        xAxis: {
          splitLine: {
            show: false,
          },
          type: 'category',
          data: dateArr,
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          type: 'value',
        },
        series: [
          {
            name: '未处理',
            data: newArr,
            type: 'line',
            areaStyle: {
              normal: {
                color: '#F46C16', // 改变区域颜色
              },
            },
            smooth: true,
            itemStyle: {
              normal: {
                color: '#F46C16',
                lineStyle: {
                  color: '#F46C16',
                },
              },
            },
          }, {
            name: '已处理',
            data: solveArr,
            type: 'line',
            smooth: true,
            areaStyle: {
              normal: {
                color: '#258EE3', // 改变区域颜色
              },
            },
            itemStyle: {
              normal: {
                color: '#258EE3',
                lineStyle: {
                  color: '#258EE3',
                },
              },
            },
          },
        ],
      }
      myChart.clear()
      myChart.setOption(option)
    },
    homeLineInfos() {
      this.showLoading = true
      let uid = new Date().getTime()
      this.uid = uid
      let endTime = this.dateNtim
      let dateArr = []
      if (this.timeFlag === '3d') {
        for (let i = 3; i > 1; i--) {
          dateArr.push(this.getTimeInterval(endTime, i))
        }
      }
      if (this.timeFlag === '7d') {
        for (let i = 7; i > 1; i--) {
          dateArr.push(this.getTimeInterval(endTime, i))
        }
      }
      if (this.timeFlag === '10d') {
        for (let i = 10; i > 1; i--) {
          dateArr.push(this.getTimeInterval(endTime, i))
        }
      }
      if (this.timeFlag === '30d') {
        for (let i = 30; i > 1; i--) {
          dateArr.push(this.getTimeInterval(endTime, i))
        }
      }
      dateArr.push(this.getTimeInterval(endTime, 0))
      let solveArr = []
      let newArr = []
      let solveCount = 0
      let newerCount = 0
      dateArr.forEach((date, index) => {
        let sTime = date + ' 00:00:00'
        let eTime = date + ' 23:59:59'
        queryAlarmCount('solve', sTime, eTime).then(resp => {
          if (uid !== this.uid) {
            return
          }
          solveCount++
          solveArr[index] = resp.data.result
          if (solveCount === dateArr.length && newerCount === dateArr.length) {
            this.init(dateArr, solveArr, newArr)
            this.showLoading = false
          }
        })
        queryAlarmCount('newer', sTime, eTime).then(resp => {
          if (uid !== this.uid) {
            return
          }
          newerCount++
          newArr[index] = resp.data.result
          if (solveCount === dateArr.length && newerCount === dateArr.length) {
            this.init(dateArr, solveArr, newArr)
            this.showLoading = false
          }
        })
      })
    },
    // 时间减n天
    getTimeInterval(date, time) { // time为减去的指定天数
      // const stime = date
      const date1 = new Date(date)
      const date2 = new Date(date1)
      // -30为30天前，+30可以获得30天后的日期
      date2.setDate(date1.getDate() - time)
      // 30天前（日，月份判断是否小于10，小于10的前面+0）
      const agoDay = `${date2.getFullYear()}-${date2.getMonth() + 1 < 10 ? `0${date2.getMonth() + 1}` : date2.getMonth() + 1}-${date2.getDate() < 10 ? `0${date2.getDate()}` : date2.getDate()}`
      return `${agoDay}`
    },
    search() {
      this.homeLineInfos()
    },
  },
}
</script>
<style>
</style>
