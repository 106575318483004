import request from '@/auth/jwt/useJwt'
// import { getToken } from '@/@core/auth/token'

/*  查询分组列表  */
// eslint-disable-next-line arrow-body-style
export function multi(params) {
  // const token = getToken()
  return request.axiosIns({
    url: 'dashboard/_multi',
    method: 'post',
    data: params,
  })
}
/* 新增分组信息  */
export function add(data) {
  return request.axiosIns({
    url: 'device/group',
    method: 'post',
    data,
  })
}
