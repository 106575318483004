<template>
  <b-card
    class="x-analysis-card"
    style="width: 100%;"
  >
    <div>
      <b-img
        :src="require('@/assets/images/cx/alarm.svg')"
        fluid class="x-section-icon"
      />
      <span class="font-weight-bolder text-secondary ml-50">设备告警总数：{{allCount}}</span>
    </div>
    <b-card-body>
      <div ref="chart" style="width: 28rem; height: 20rem;"></div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BImg } from 'bootstrap-vue'
import Vue from 'vue'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/grid'
import 'echarts/lib/component/title'
import {
  queryAlarmCount,
} from '@/api/home/alarm-view'
// import { $themeColors } from '@themeConfig'
import theme from '@core/components/charts/echart/theme.json'

echarts.registerTheme('theme-color', theme)
Vue.prototype.$echarts = echarts

export default {
  components: {
    BCard,
    BCardBody,
    BImg,
  },
  data() {
    return {
      allCount: 0,
      newCount: 0,
      historyCount: 0,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const myChart = this.$echarts.init(this.$refs.chart, theme)
      const option = {
        title: {
          text: '',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {},
        grid: {
          y: 200,
          left: '2%',
          right: '10%',
          top: '18%',
          bottom: '50%',
          containLabel: true,
        },
        xAxis: {
          splitLine: {
            show: false,
          },
          type: 'value',
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          type: 'category',
          data: [],
        },
        series: [
          {
            name: '未处理',
            type: 'bar',
            data: [this.newCount],
            color: '#F46C16',
            itemStyle: { // 上方显示数值
              normal: {
                label: {
                  show: true, // 开启显示
                  position: 'right', // 在上方显示
                  textStyle: { // 数值样式
                    color: '#F46C16',
                    fontSize: 10,
                  },
                },
              },
            },
          },
          {
            name: '已处理',
            type: 'bar',
            data: [this.allCount - this.newCount],
            color: '#258EE3',
            itemStyle: { // 上方显示数值
              normal: {
                label: {
                  show: true, // 开启显示
                  position: 'right', // 在上方显示
                  textStyle: { // 数值样式
                    color: '#258EE3',
                    fontSize: 10,
                  },
                },
              },
            },
          },
        ],
      }
      queryAlarmCount(undefined).then(resp => {
        this.allCount = resp.data.result
        queryAlarmCount('newer').then(res => {
          this.newCount = res.data.result
          this.historyCount = this.allCount - this.newCount
          option.series[0].data = [this.newCount]
          option.series[1].data = [this.historyCount]
          myChart.setOption(option)
        })
      })
    },
  },
}
</script>
