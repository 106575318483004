import request from '@/auth/jwt/useJwt'

/*  查询产品总数 */
export function queryProductCount(state, createTime) {
  let requestUrl = '/device-product/_count'
  if (state === 0) {
    requestUrl += `?terms[0].column=state&terms[0].value=${state}`
  }
  if (createTime > 100) {
    requestUrl += `?terms[0].column=createTime$LT&terms[0].value=${createTime}`
  }
  if (createTime > 100 && state === 0) {
    requestUrl += `?terms[0].column=createTime$LT&terms[0].value=${createTime}&terms[1].column=state&terms[1].value=${state}`
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
// 查询所有产品
export function queryAllProducts() {
  return request.axiosIns({
    url: '/device-product/_query/no-paging?sorts[0].name=createTime&sorts[0].order=desc',
    method: 'get',
  })
}
